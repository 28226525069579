/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// force dark mode : ( maybe a theme issue)
exports.onInitialClientRender = () => {
  localStorage.setItem('theme-ui-color-mode', 'dark')
}

require("typeface-inter");
