import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui'

export default {
  ...novelaTheme,
  initialColorMode: `dark`,
  colors: {
    ...novelaTheme.colors,
    modes: {
      dark: {
        ...novelaTheme.colors.modes.dark,
        accent: '#fffff',
        gradient: 'linear-gradient(180deg, #000000 0%, #000000 100%)',
      },
    },
  },
}
